<template>
  <main class="main pa-5">
    <section class="invoice">
      <div class="invoice__wrapper">
        <h1 class="invoice__title">Инвойс</h1>
        <div class="invoice__info">
          <div class="invoice__info-block">
            <span>From</span>
            <h5>Admin, Inc.</h5>
            <span>795 Folsom Ave, Suite 600</span>
            <span>San Francisco, CA 94107</span>
            <span>Phone: (804) 123-5432</span>
            <span>Email: info@almasaeedstudio.com</span>
          </div>
          <div class="invoice__info-block">
            <span>To</span>
            <h5>John Doe</h5>
            <span>795 Folsom Ave, Suite 600</span>
            <span>San Francisco, CA 94107</span>
            <span>Phone: (804) 123-5432</span>
            <span>Email: info@almasaeedstudio.com</span>
          </div>
          <div class="invoice__info-block">
            <h5>Invoice #007612</h5>
            <div>
              <span class="invoice__info-block--bold">Order ID: </span>
              <span>4F3S8J</span>
            </div>
            <div>
              <span class="invoice__info-block--bold">Payment Due: </span>
              <span>2/22/2014</span>
            </div>
            <div>
              <span class="invoice__info-block--bold">Account: </span>
              <span>968-34567</span>
            </div>
          </div>
        </div>
        <div class="invoice__table-wrapper">
          <table id="invoice__table">
            <tr>
              <th>QTY</th>
              <th>Product</th>
              <th>Serial#</th>
              <th>Description</th>
              <th>Subtotal</th>
            </tr>
            <tr>
              <td>1</td>
              <td>Lrem ipsum</td>
              <td>455-445-432</td>
              <td
                >Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.</td
              >
              <td>$64</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Lrem ipsum</td>
              <td>455-445-432</td>
              <td
                >Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.</td
              >
              <td>$64</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Lrem ipsum</td>
              <td>455-445-432</td>
              <td
                >Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.</td
              >
              <td>$64</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Lrem ipsum</td>
              <td>455-445-432</td>
              <td
                >Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.</td
              >
              <td>$64</td>
            </tr>
          </table>
        </div>

        <div class="payment__content">
          <div class="payment__content-right">
            <span class="payment__title">Payment Methods:</span>
            <div class="payment__methods">
              <i class="visa-icon"></i>
              <i class="mastercard-icon"></i>
              <p>VISA | MasterCard | American Express</p>
            </div>
            <p class="payment__text"
              >Etsy doostang zoodles disqus groupon greplin oooj voxy zoodles,
              weebly ning heekya handango imeem plugg dopplr jibjab, movity
              jajah plickers sifteo edmodo ifttt zimbra.
            </p>
          </div>
          <div class="payment__content-left">
            <span class="payment__title">Amount Due 2/22/2014</span>
            <ul class="payment__list pa-0">
              <li>
                <span class="payment__list-title">Subtotal: </span>
                <span>$250.30</span>
              </li>
              <li>
                <span class="payment__list-title">Tax (9.3%): </span>
                <span>$10.34</span>
              </li>
              <li>
                <span class="payment__list-title">Shipping: </span>
                <span>$5.80</span>
              </li>
              <li>
                <span class="payment__list-title">Total: </span>
                <span>$265.24</span>
              </li>
            </ul>
            <!-- <button style="padding: 10px; background-color: green;"
              >print</button
            > -->
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.invoice__title {
  font-family: 'Alegreya Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 17px;
  color: #423f3d;
  margin-bottom: 44px;
}

.invoice__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 40px;
  flex-wrap: wrap;

  &-block {
    width: 268px;
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      border-right: 1px solid #f3f1f1;
    }
    & > div {
      display: flex;
    }

    h5 {
      font-family: 'Alegreya Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #31343c;
      margin: 20px 0 10px 0;
    }

    span {
      font-family: 'Alegreya Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #31343c;
    }
    .invoice__info-block--bold {
      white-space: pre;
      font-weight: 700;
    }
  }
}

.payment__title,
.payment__methods {
  margin-bottom: 30px;
  display: inline-block;
}

.invoice__table-wrapper {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.invoice__wrapper {
  background-color: #ffffff;
  border-radius: 1px;
  padding: 27px 21px 0 27px;
}

.payment__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 122px;
  flex-wrap: wrap;

  .payment__text,
  .payment__title {
    font-family: 'Alegreya Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #31343c;
  }

  &-right {
    max-width: 584px;
  }

  &-left {
    .payment__list-title {
      font-family: 'Alegreya Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #31343c;
      white-space: pre;
    }

    .payment__list {
      li {
        font-family: 'Alegreya Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #31343c;
        padding: 5px 0;
      }
    }
  }
}

.payment__methods {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-family: 'Alegreya Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #666666;
    margin-left: 34px;
  }
}

.visa-icon {
  background-image: url('/img/icons/visa-icon.svg');
  background-repeat: no-repeat;
  display: inline-block;
  width: 52px;
  height: 37px;
  margin-right: 6px;
}

.mastercard-icon {
  background-image: url('/img/icons/mastercard-icon.svg');
  background-repeat: no-repeat;
  display: inline-block;
  width: 59px;
  height: 37px;
}

#invoice__table {
  width: 100%;
  margin-bottom: 64px;
}

#invoice__table td {
  padding: 8px;
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  white-space: nowrap;
}

#invoice__table th {
  padding: 8px;
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  text-align: center;
}

#invoice__table tr:not(:first-child) {
  height: 73px;
  border-bottom: 1px solid #f5f5f5;
}

#invoice__table tr:first-child {
  height: 40px;
}

#invoice__table tr:first-child th,
#invoice__table tr td:first-child {
  color: #423f3d;
}

#invoice__table tr td {
  color: #31343c;
}

#invoice__table tr:nth-child(odd) {
  background-color: #fbfbfb;
}

.payment__content-left {
  @media screen and (max-width: 880px) {
    margin-top: 20px;
  }
}
</style>
